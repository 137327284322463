import * as Sentry from '@sentry/browser';
import { navigate } from 'gatsby';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { SynchronousCachePersistor } from 'apollo3-cache-persist';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
// use polyfill so xhr capture works with e2e tests and we have consistent behavior
// at run time and e2e testing.
import { fetch as polyFetch } from 'fetch-everywhere';

import isBrowser from 'src/helpers/isBrowser';
import { agentDomains } from '../constants/agent';
import setHeaders from 'src/helpers/setHeaders';

// For membership log
export const memberGraphMetaData = {
  hasLogged: false,
};

const nullStorage: Storage = {
  length: 0,
  getItem: () => null,
  setItem: () => undefined,
  removeItem: () => undefined,
  clear: () => undefined,
  key: (): string => null,
};

const authRestLink = setContext(async (_, { headers }) => {
  const restHeaders = await setHeaders();
  return {
    headers: {
      ...headers,
      ...restHeaders,
      Accept: 'application/json',
    },
  };
});
export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  graphQLErrors &&
    graphQLErrors.map(({ message }) => console.log(`GraphQL Error: ${operation.operationName}: ${message}`));
  networkError && console.log(`Network Error: ${networkError.message}`);
  if (networkError && 'response' in networkError && networkError.response?.status === 401) {
    // testing
    cachePersistor.purge();
    navigate('/login-redirect/');
  }

  if (
    networkError &&
    'response' in networkError &&
    process.env.SENTRY_DSN &&
    process.env.SENTRY_ENVIRONMENT !== 'local'
  ) {
    const transactionId = networkError.response?.headers.get('x-transaction-id');
    const result = 'result' in networkError && networkError.result;
    const statusCode = networkError.statusCode;
    Sentry.addBreadcrumb({
      category: 'networkError',
      message: 'Network error ' + transactionId,
      data: {
        transactionId,
        result,
        statusCode,
      },
      level: Sentry.Severity.Error,
    });
  }
  return forward(operation);
});

export const cache = new InMemoryCache();

const getIsAgent = () => {
  if (!isBrowser) return false;

  const hostname = window.location.hostname;
  const hasAgentDomain = agentDomains.includes(hostname);
  const hasShelbyFlag = window.localStorage.getItem('SHELBY') === '1';

  return hasAgentDomain || hasShelbyFlag;
};

export const isAgent = getIsAgent();

// export so auth can wipe cache.
export const cachePersistor = new SynchronousCachePersistor({
  cache,
  storage: isBrowser ? window.localStorage : nullStorage,
  maxSize: false,
  debug: process.env.NODE_ENV !== 'production',
  key: isAgent ? 'apollo-cache-persist-shelby' : 'apollo-cache-persist',
});

// For membership log
const omniAfterwareLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const time = new Date() - context.start;

    if (operation.operationName === 'MembershipQuery') {
      const { restResponses } = context;
      const headers = restResponses[0].headers;
      let xTransactionId;

      if (headers) {
        xTransactionId = headers.get('x-transaction-id');
      }

      const logResponse = {
        data: {
          membership: {
            data: {
              ...response.data.membership.data,
            },
          },
          log: {
            omniResponseTime: time,
            omniResponseID: xTransactionId,
          },
        },
      };
      return logResponse;
    }

    return response;
  });
});
// For membership log
const memberGraphAfterwareLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const time = new Date() - context.start;

    const {
      response: { headers },
    } = context;
    let xAmzonRequestId;

    if (headers) {
      xAmzonRequestId = headers.get('x-amzn-requestid');
    }

    const logResponse = {
      data: {
        member: {
          ...response.data.member,
        },
        log: {
          memberGraphResponseTime: time,
          memberGraphResponseId: xAmzonRequestId,
        },
      },
    };

    // Reset hasLogged so customer member graph can re-populate
    memberGraphMetaData.hasLogged = false;
    return logResponse;
  });
});

const restLink = new RestLink({
  uri: isBrowser ? (isAgent ? process.env.BROKERS_API_URL : process.env.OMNI_URL) : '',
  customFetch: polyFetch,
});

/**
 * Terms and conditions aka AMS api linking
 */
const authTermsLink = setContext(async (_, { headers }) => {
  const termsHeaders = await setHeaders('terms-api');
  return {
    headers: {
      ...headers,
      ...termsHeaders,
    },
  };
});

// Terms & Conditions + Commissions - https://hdc-ams-api.{env}.hagerty.com/graphql
const termsLink = createHttpLink({
  uri: process.env.TERMS_URL,
});

// Customer Membership - https://customer-members.{env}.hagerty.com/graphql
const customerMembershipLink = createHttpLink({
  uri: process.env.CUSTOMER_MEMBERSHIP_URL,
});

// Membership - https://hdcmembership.{env}.hagerty.com/graphql
const membershipLink = createHttpLink({
  uri: process.env.MEMBERSHIP_URL,
});

console.log(`Running HDC with ${isAgent ? 'brokers' : 'users'}' APIs...`);
console.log(isBrowser ? (isAgent ? process.env.BROKERS_API_URL : process.env.OMNI_URL) : '');
const restLinkFrom = ApolloLink.from([authRestLink, errorLink, omniAfterwareLink.concat(restLink)]);

export enum ServiceName {
  CustomerMember,
  Terms,
}

const graphqlLinkSplit = ApolloLink.split(
  (operation) => operation.getContext().serviceName === ServiceName.CustomerMember,
  memberGraphAfterwareLink.concat(customerMembershipLink),
  ApolloLink.split(
    (operation) => operation.getContext().serviceName === ServiceName.Terms,
    authTermsLink.concat(termsLink),
    membershipLink
  )
);

const link = ApolloLink.from([restLinkFrom, graphqlLinkSplit]);

const client = new ApolloClient({ cache, link });

try {
  // TODO: only restore cache when schema is unchanged. https://github.com/apollographql/apollo-cache-persist#ive-had-a-breaking-schema-change-how-do-i-migrate-or-purge-my-cache
  // cachePersistor.restoreSync();
} catch (error) {
  console.error('Error restoring Apollo cache', error);
}

export default client;
