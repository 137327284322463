import React, { useEffect, useContext } from 'react';
import isBrowser from 'src/helpers/isBrowser';
import { Context } from 'src/models/context';

// Conditionally import from browser or server side code.
//
const useAuthentication = isBrowser ? require('./browser').default : require('./server').default;

export const useRequireAuthenticated = (afterLoginUrl?: string) => {
  const { loading, isAuthenticated, login } = useAuthentication();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    login({ redirect_uri: afterLoginUrl }).then(() => {}, console.error);
  }, [loading, isAuthenticated]);
};

export const AuthenticationContext = React.createContext<Partial<Context>>({ loading: true, isAuthenticated: false });

type Props = {
  children: React.ReactChildren;
};

export const AuthenticationProvider: React.FC<Props> = ({ children }) => {
  const value = useAuthentication();
  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export const useAuthContext = () => useContext(AuthenticationContext);
