export enum Split {
  MoneyPlatformIintegration = 'money_platform_integration',
  ExperimentMonthlyPricing = 'experiment_monthly_price_test',
  ExperimentAlreadyMemberCta = 'experiment_already_member_cta',
  FeaturePartnerOfferPageRedirection = 'feature_partner_offer_page_redirection',
  FeatureAgentOnboarding = 'feature_agent_onboarding',
  FeatureFreeTrial = 'feature_free_trial',
  FeatureCustomerPaymentDisclaimer = 'feature_customer_payment_disclaimer',
  FeatureCancellationReasons = 'feature_cancellation_reasons',
  FeaturePurchaseFlowOptimization = 'feature_purchase_flow_optimization',
  FeatureMarketplaceCopyUpdate = 'feature_marketplace_copy_update',
  FeaturePreventDuplicateMemberships = 'feature_prevent_duplicate_memberships',
  featureConsentToMarket = 'feature_consent_to_market',
  FeatureEventsPage = 'feature_events_page',
  FeatureInsurancePages = 'feature_insurance_landing_pages_replatform',
  FeatureStfCustomerStandalonePurchase = 'feature_stf_customer_standalone_purchase',
  FeatureCreateAccountCheckoutFlow = 'feature_create_account_checkout_flow',
  FeatureBenefitsPage = 'feature_hdc_benefits_page',
  FeatureAskHagerty = 'feature_ask_hagerty',
  FeatureMemberFeedbackSurvey = 'feature_member_feedback_survey',
  FeatureMemberVoting = 'feature_member_voting',
  FeatureDigitalMagazineLink = 'feature_digital_magazine_link',
}

export enum Treatment {
  On = 'on',
  Off = 'off',
  Omni = 'omni',
  MemberGraph = 'member_graph',
  BothOmni = 'both_omni',
  BothMemberGraph = 'both_member_graph',
}
