import React, { useState } from 'react';
import { TrackingContext } from './TrackingContext';
import { TrackInteractionFn, TrackModalViewFn, TrackViewFn } from 'src/components/TrackingContext/types';

export const TrackingProvider: React.FC = ({ children }) => {
  const [trackView, setTrackView] = useState<TrackViewFn>(null);
  const [trackInteraction, setTrackInteraction] = useState<TrackInteractionFn>(null);
  const [trackModalView, setTrackModalView] = useState<TrackModalViewFn>(null);

  const setTrackingFns = (
    trackViewFn: TrackViewFn,
    trackInteractionFn: TrackInteractionFn,
    trackModalViewFn: TrackModalViewFn
  ) => {
    setTrackView(() => trackViewFn);
    setTrackInteraction(() => trackInteractionFn);
    setTrackModalView(() => trackModalViewFn);
  };

  return (
    <TrackingContext.Provider value={{ trackView, trackInteraction, trackModalView, setTrackingFns }}>
      {children}
    </TrackingContext.Provider>
  );
};
