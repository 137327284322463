import Auth0ClientFacade from 'src/components/AuthProvider/auth0ClientFacade';
import userManager from './userManager';

const client = new Auth0ClientFacade(userManager);

class AuthUtils {
    private client = null;
    constructor (client) {
        this.client = client;
    }

    async login(params: any = {}) {
        const { pathname, search } = window.location;
      
        const targetUrl = params?.redirect_uri || `${pathname}${search}`;
        const defaultState = { targetUrl };
        const state = Object.assign(defaultState, params?.state || {});
        const composedParams = Object.assign({}, params);
        // merge state separate since Object.assign doesn't do a recursive merge.
        composedParams.state = state;
        return await this.client.loginWithRedirect(composedParams);
    }

    async refreshLogin() {
        console.log('refresh...');
        try {
          const user = await this.client.refreshSilently();
          console.log('refreshed.');
          return user;
        } catch (e) {
          console.log('Failed to refresh:\n', e);
        }
    }

    async handleRedirectCallback() { 
        return await client.handleRedirectCallback();
    }

    logout() {
        this.client.logout;
    }

    async refreshSilently() {
        return await this.client.refreshSilently();
    }

    isAuthenticated() {
        return this.client.isAuthenticated();
    }

    getUser() {
        return this.client.getUser();
    }
}
const authUtils = new AuthUtils(client);
export default authUtils;

