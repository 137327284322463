import { useContext, useEffect, useState } from 'react';
import { SplitContext } from '@splitsoftware/splitio-react';

import { useUserTracker } from 'src/hooks/useUserTracker';
import { trackEvent, trackPageLoad } from 'src/services/tracker';
import { useTrackingContext } from 'src/components/TrackingContext/useTrackingContext';
import { CustomTrackingProperties } from 'src/components/TrackingContext/types';

/**
 * useTracking should only be called on page level components. This hook will automatically register
 * experiments and set up the TrackingContext for children to use with the correct values. It will
 * also automatically call the page level tracking event.
 * @param tagName the name of the initial page level tracking tag
 * @param experiments list of experiments to include in tracking call - is sensitive to reactive list to accommodate the fact that the split sdk is asynchronous
 */
export const useTracking = (tagName: string, experiments: string[] = null, disableDefaultPageView = false) => {
  const [pageHasLoaded, setPageHasLoaded] = useState(false);
  const user = useUserTracker();
  const { isReady } = useContext(SplitContext);
  const { setTrackingFns } = useTrackingContext();

  useEffect(() => {
    const trackView = (
      viewTag: string = tagName,
      testExperience?: string[],
      customProperties: CustomTrackingProperties = {}
    ) => {
      trackPageLoad({
        _tag_name: viewTag,
        ...user,
        ...customProperties,
        test_experience: testExperience ? testExperience : experiments || [],
      });
    };

    const trackInteraction = (
      action?: string,
      actionName?: string,
      customProperties: CustomTrackingProperties = {},
      interactionTag = 'page_interaction'
    ) => {
      trackEvent({
        _tag_name: interactionTag,
        ...user,
        ...customProperties,
        action,
        action_name: actionName,
      });
    };

    const trackModalView = (
      modalType: string,
      testExperience?: string[],
      customProperties: CustomTrackingProperties = {},
      viewTag = 'modal_view'
    ) => {
      trackPageLoad({
        _tag_name: viewTag,
        ...user,
        ...customProperties,
        test_experience: testExperience ? testExperience : experiments || [],
        modal_type: modalType,
      });
    };

    if (user && isReady && !pageHasLoaded) {
      if (experiments !== null && experiments.length === 0) {
        return;
      }

      /**
       * Disable default `trackView` call
       * needed for calling trackView within page component
       */
      if (!disableDefaultPageView) trackView();

      setPageHasLoaded(true);
      setTrackingFns(trackView, trackInteraction, trackModalView);
    }
  }, [user, isReady, pageHasLoaded, experiments]);
};
