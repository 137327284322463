import { UserManager } from 'oidc-client';
import { cachePersistor } from 'src/api/client';
class Auth0ClientFacade {
    constructor(private userManager: UserManager) {
      this.userManager = userManager;
    }
  
    async getIdTokenClaims() {
      const user = await this.userManager.getUser();
      const claims = user.profile;
      claims.__raw = user.id_token;
      return claims;
    }
  
    async getTokenSilently() {
      const user = await this.userManager.getUser();
      return user.access_token;
    }
  
    async getUser() {
      // const { login }  = useAuthContext();
      const user = await this.userManager.getUser();
      if (!user) {
        // login();
        return;
      }
  
      const profile = user.profile;
      if (!profile.name) {
        profile.name = profile.preferred_username;
      }
      return profile;
    }
  
    async handleRedirectCallback() {
      await cachePersistor.purge();
      await this.userManager.clearStaleState();
      const user = await this.userManager.signinCallback();
      const appState = user?.state ?? {};
      return { appState, user };
    }
  
    async isAuthenticated() {
      const user = await this.userManager.getUser();
      return !!user;
    }
  
    async loginWithRedirect(params) {
      await cachePersistor.purge();
      await this.userManager.clearStaleState();
      if (process.env.HDC_FLAG_OIDC_RELAX === 'true') {
        params.extraQueryParams = { relax: process.env.OIDC_RELAX_SECRET };
      }
      return this.userManager.signinRedirect(params);
    }
  
    async refreshSilently() {
      const user = await this.userManager.signinSilent();
      console.log(user);
      await this.userManager.storeUser(user);
      return user;
    }
  
    async logout() {
      sessionStorage.clear();
      await cachePersistor.purge();
      await this.userManager.clearStaleState();
      const extraQueryParams = { returnTo: origin };
      return this.userManager.signoutRedirect({ extraQueryParams });
    }
  }

  export default Auth0ClientFacade;