import { useState, useEffect } from 'react';

import useMembership from 'src/api/useMembership';
import useProfile from 'src/api/useProfile';
import { useAuthContext } from 'src/components/AuthProvider';
import { MemberStatus } from './GlobalSiteHeader.types';

export const useUserState = (location: Location) => {
  const { loading: authLoading, isAuthenticated, login, refresh, logout } = useAuthContext();
  const [status, setStatus] = useState<MemberStatus>(MemberStatus.Anonymous);

  let loading = true;
  let isMember = false;
  let firstName = '';
  let lastName = '';

  const options = { skip: location.pathname.includes('/callback') || !isAuthenticated || authLoading };
  const { data: omniProfile, loading: profileLoading } = useProfile(options);
  const { data: membership, loading: membershipLoading, hasLegacyMembership } = useMembership(options);

  loading = authLoading || profileLoading || membershipLoading;
  isMember = membership?.hasMembership() || hasLegacyMembership;
  firstName = omniProfile ? `${omniProfile?.givenName}` : '';
  lastName = omniProfile ? `${omniProfile?.familyName}` : '';

  useEffect(() => {
    if (!isAuthenticated) {
      setStatus(MemberStatus.Anonymous);
    } else {
      setStatus(isMember ? MemberStatus.Member : MemberStatus.NonMember);
    }
  }, [isAuthenticated, isMember]);

  return { loading, isAuthenticated, status, firstName, lastName, login, refresh, logout };
};
